var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.unverifiedEmailDialog,
            callback: function ($$v) {
              _vm.unverifiedEmailDialog = $$v
            },
            expression: "unverifiedEmailDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("div", { staticClass: "px-6 pt-8" }),
                _c("div", { staticClass: "px-10" }, [
                  _c(
                    "p",
                    { staticClass: "medium-font font-25 color-primary" },
                    [_c("strong", [_vm._v("Info")])]
                  ),
                  _c(
                    "p",
                    { staticClass: "medium-font font-18 color-primary" },
                    [_vm._v("Please verify your email address before login. ")]
                  ),
                ]),
              ]),
              _c("v-card-actions", { staticClass: "justify-end" }, [
                _c(
                  "div",
                  { staticClass: "my-6" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "color-primary",
                        attrs: { text: "", outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.verifyOk()
                          },
                        },
                      },
                      [_vm._v(" Send Verification Link ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-6",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.okAction()
                          },
                        },
                      },
                      [_vm._v(" Ok ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }