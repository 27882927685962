




























// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { LoginService } from '@services/login.service';
import { LSS } from '../core/services';
import { FlowService } from '@services/flow.service';
import { API_ORY_URL } from '@config'
import { decodeToken } from '@shared/utils/commom.util'
import { SignUpService } from '@services/signup.service';
import { CB_CREATE_CUSTOMER, CB_Subs } from 'src/utils/apptweakqueries';
import { DETAILS_FROM_USER_TEAMS, FETCH_CHART_PRESETS, FETCH_TABLE_PRESETS, FETCH_OVERVIEW_PRESETS, INTEGRATIONS_INFO, FETCH_CUSTOM_PRESETS } from 'src/utils/apollo-queries';
import { Roles } from 'src/utils/enums';
import { OnboardService } from '@services/onboard.service';

export default Vue.extend({
  name: 'OnboardFork',
  data() {
    return {
      unverifiedEmailDialog: false,
      verifyEmail: '',
      fullscreenLoader: null,
      IsDisabled: true,
      IsSubCancelled: false,
      asaIntegrationPreset: false
    };
  },
  components: {},
  methods: {
    saveSessionLSS(session, user) {
      LSS.sessId = session.id;
      this.isOnBoarded(user);
    },
    getSession() {
      this.fullscreenLoader = this.$loading.show();
      FlowService.whoIamI()
        .then(async (res) => {
          const user = await this.getUserDetails(res.identity.id, res.id);
          this.fullscreenLoader.hide();
          this.saveSessionLSS(res, user);
        })
        .catch((err) => {
          this.fullscreenLoader.hide();
          alert('Unable to Log in. Please try again or contact support@searchadsmaven.com if issue persists.')
          window.location.href = Vue.prototype.$out_url
        });
    },
    async getLogoutFlow() {
      const res = await FlowService.fetchFlow(this.flow, 'logout')
        .catch((err) => {
          console.log(err)
        });
    },
    okAction() {
      window.location.href = Vue.prototype.$out_url
    },
    verifyOk() {
      this.sendMail(this.verifyEmail);
    },
    verifyCancel() {
      window.location.href = Vue.prototype.$out_url
    },
    async _onboardAndTakeToSetup() {
      try {
        const onboardResponse = await OnboardService.onboardFirst(decodeToken(LSS.token).UserId);

        if (decodeToken(LSS.token).Role == 'ADMIN') {
          await this.createSubPlan();
        }
        const user = await this.getUserDetailsOnboard(decodeToken(LSS.token).UserId);
        this.$router.push('/overview');
      } catch {
        this.$notify.error(this.$t('successMsg.requestFailMsg'));
      }
    },
    async isOnBoarded(user) {
      this.verifyEmail = user.Email;
      if (!user.IsVerified) {
        return this.unverifiedEmailDialog = true;
      }
      if (user.subscriptionStatus == 'cancelled' && decodeToken(LSS.adminToken) && decodeToken(LSS.adminToken).Role != Roles.SUPERADMIN) {
        return this.$router.push('/expired-free-trial');
      }
      if (user.IsOnboarded) {
        this.fetchOverviewPresets();
        // this.fetchChartDatasetPresets();
        // this.fetchTableDatasetPresets();
        this.fetchCustomViews();
        LSS.isRefetchRequired = false
        return this.$router.push('/overview');
      }
      this._onboardAndTakeToSetup();
    },
    async subscriptionDetailsFetch(custId) {
      await this.$apollo
        .query({
          query: CB_Subs,
          variables: {
            custId: custId,
          },
        })
        .then((res) => {
          //console.log(res.data.getSubsForCust)
          // if(res.data.getSubsForCust.list[0].subscription.status == 'cancelled'){
          //   this.IsSubCancelled = true;
          // }
        })
        .catch((error) => {
          return error;
        });
    },
    /**
     * Imported from onboard.vue, it might not be required, we'll check.
     * @param UserID 
     */
    async getUserDetailsOnboard(UserID: string) {
      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: null,
      });
      const user = decodeToken(token);
      LSS.token = token;
      this.$root.$emit('userNameFetched', user);
      return user;
    },
    async createSubPlan() {
      const user = decodeToken(LSS.token)
      await this.$apollo
        .query({
          query: CB_CREATE_CUSTOMER,
          variables: {
            first_name: user.FirstName,
            last_name: user.LastName,
            cust_id: user.TeamID,
            email: user.Email,
            company: user.CompanyName,
          },
        })
        .then((data) => {
        })
        .catch((error) => {
        });
    },
    async getUserDetails(UserID: string, SessID: string) {

      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: SessID
      }).catch(e => {
        alert('Unable to Log in. Please try again or contact support@searchadsmaven.com if issue persists')
        return false
      });
      const user = decodeToken(token);
      if (!user || !user.TeamID || !user.UserId) {
        alert('Unable to Log in. Please try again or contact support@searchadsmaven.com if issue persists.')
        LSS.clear();
        //console.log(Vue.prototype.$out_url)
        window.location.href = Vue.prototype.$out_url
      }
      LSS.token = token
      await this.getDetailsFromUserTeam(user.TeamID, user.UserId)
      this.$root.$emit('userNameFetched', user);
      await this.integrationsInfo()
      return user;
    },
    async getDetailsFromUserTeam(TeamId, UserId) {
      if (!TeamId || !UserId) {
        return
      }
      await this.$apollo.query({
        query: DETAILS_FROM_USER_TEAMS,
        variables: {
          TeamId: TeamId,
          UserId: UserId
        },
      })
        .then((res) => {
          if (res.data.getDetailsFromUserTeam != null) {
            this.IsDisabled = res.data.getDetailsFromUserTeam?.IsDisabled
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async integrationsInfo() {
      try {
        const result = await this.$apollo.query({
          query: INTEGRATIONS_INFO
        });
        const info = JSON.parse(result.data.getIntegrationInfo.info);
        this.asaIntegrationPreset = info.APPLE_SEARCH_ADS;
        LSS.firstSyncDone = result.data.getIntegrationInfo.firstSyncDone;
        if (this.asaIntegrationPreset != undefined && this.asaIntegrationPreset != null) {
          LSS.asaInt = this.asaIntegrationPreset
          this.$root.$emit('asaInt-fetched', this.asaIntegrationPreset);
        }
        else LSS.asaInt = false
      } catch (error) {
        console.log(error)
        this.asaIntegrationPreset = false;
      }
    },
    async sendMail(email) {
      this.fullscreenLoader = this.$loading.show();
      try {
        const mail = await SignUpService.sendVerifyLink({email: email});

        if(mail != 'success') throw mail;
        this.unverifiedEmailDialog = false;
        this.$notify.success('Email verification link sent successfully.');
        setTimeout(() => {
          window.location.href = Vue.prototype.$out_url
        }, 1500)
      } catch(error) {
        this.$notify.error('Failed to send verification link.');
      }

      this.fullscreenLoader.hide();
    },
    fetchChartDatasetPresets() {
      this.$apollo
        .query({
          query: FETCH_CHART_PRESETS,
        })
        .then((info) => {
          // console.log(info.data.fetchChartPresets);
          LSS.chartDatasets = info.data.fetchChartPresets;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchTableDatasetPresets() {
      this.$apollo
        .query({
          query: FETCH_TABLE_PRESETS,
        })
        .then((info) => {
          LSS.datatableDatasets = info.data.fetchTablePresets
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchOverviewPresets() {
      this.$apollo
        .query({
          query: FETCH_OVERVIEW_PRESETS,
        })
        .then((info) => {
          LSS.overviewDD = JSON.parse(info.data.fetchOverviewPresets.overview)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCustomViews() {
      this.$apollo
        .query({
          query: FETCH_CUSTOM_PRESETS,
        })
        .then((info) => {
          for (const data of info.data.fetchCustomView) {
            try {
              data.DatatableDatasets = JSON.parse(data.DatatableDatasets)
            }
            catch (e) { }
          }
          LSS.customViews = info.data.fetchCustomView
          this.pinnedLoadAccount();
        })
        .catch((error) => {
          LSS.customViews = []
          this.pinnedLoadAccount();
          //console.log(error);
        });
    },
    pinnedLoadAccount() {
      const getCurrentTempViews = LSS.customViews.find(o => o.CustomView == 'TempView' && o.tab == "accounts");
      if (getCurrentTempViews) return;
      const customView = [{
        _id: '1', CustomView: 'TempView',
        tab: "accounts",
        DatatableDatasets: ''
      }, ...LSS.customViews];
      LSS.customViews = customView;
    },
  },
  async mounted() {
    const res = await FlowService.fetchFlow(this.flow, 'logout')
      .catch((err) => {
        console.log(err)
      });
    Vue.prototype.$out_url = res?.logout_url || ''
    this.getSession();
    LSS.deleteIntStatus = [];
    // this.fetchCustomViews();
    //     LSS.isRefetchRequired = false
    //     return this.$router.push('/overview');
  },
});
